<template>
  <div :class="[{ 'search-container-nav': mode === 'nav' }, 'search-container']"
    :style="mode == 'label_page' ? 'background: rgba(255, 255, 255, 0.2)' : boxStyle">
    <div class="input">
      <div class="dropdown" v-if="mode === 'page' && $route.name !== 'music' && $route.name !== 'video'">
        <div class="dropdown-content">{{ selectList[selectIndex].name }}</div>
        <i class="el-icon-arrow-down"></i>
        <div class="dropdown-list">
          <p v-for="(item, index) in selectList" :key="index" class="item" @click="onSelectItemClick(index, item)">
            {{ item.name }}
          </p>
        </div>
      </div>
      <el-input :style="mode !== 'page' || $route.name === 'music' || $route.name === 'video' ? 'border: none;' : ''"
        v-model="keyword_name" class="input-text"
        :class="mode === 'page' ? 'color-white' : mode === 'label_page' ? 'dark_color' : ''" :placeholder="placeholder"
        @keydown.enter.native="handleSearch">
      </el-input>
      <!--      <div class="identify" v-if="mode !== 'nav' && showIdent">-->
      <!--        <i class="iden_icon"></i>-->
      <!--        <span class="iden_text" @click="goPage">歌曲识别</span>-->
      <!--      </div>-->
    </div>
    <div class="btn" :style="btnStyle" @click="handleSearch">
      <i class="el-icon-search"></i>
      <!-- v-if="mode === 'nav'" -->
      <span>搜索</span>
    </div>
  </div>
</template>

<script>
import { HeaderMenuList } from '@/config'
export default {
  name: 'Search',
  props: {
    // 'page': 页面中使用 'nav': 导航栏使用
    mode: {
      type: String,
      default: 'page'
    },
    // 输入框占位符
    placeholder: {
      type: String,
      default: '输入关键词搜索'
    },
    // 整个搜索框样式
    boxStyle: Object,
    // 搜索按钮样式
    btnStyle: Object,
    showIdent: {
      type: Boolean,
      default: true
    },
    limitExempt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyword_name: '',
      selectIndex: 0,
      type: 2 // 选中的type信息
    }
  },
  mounted() {
    let keyword_name = this.$route.query.keyword_name;
    this.keyword_name = keyword_name ? typeof (keyword_name) == 'string' ? keyword_name : keyword_name.join(',') : ''
    this.$emit('onSelectChange', HeaderMenuList[1])
  },
  computed: {
    selectList() {
      const list = JSON.parse(JSON.stringify(HeaderMenuList))
      list.shift()
      list.pop()
      return this.limitExempt ? list.slice(0,3) : list.slice(0,4)
    },
    path() {
      return this.$route.path
    },
    /**
     * 是否音效页面
     */
    isEffectPage() {
      return this.path === '/soundEffect'
    },
    /**
     * 是否首页页面
     */
    isHomePage() {
      return this.path === '/playList'
    }
  },
  methods: {
    // 跳转到歌曲识别页
    goPage() {
      this.$router.push('/identify')
    },
    /**
     * 点击下拉选项
     * @param {Number} index 当前点击下标
     * @param {Object} item 当前项详情
     */
    onSelectItemClick(index, item) {
      this.selectIndex = index
      this.type = item.type
      this.$emit('onSelectChange', item)
    },
    /**
     * 处理搜索
     * 2021-12-23 17:29:58
     * @author SnowRock
     */
    handleSearch() {
      const { keyword_name, isEffectPage, isHomePage, mode } = this
      let { type } = this
      // 如果在音效页面下导航内
      if (isEffectPage && mode === 'nav') {
        type = 3
      } else if(this.limitExempt){
        let typelist= ['music', 'sound', 'video']
        type = typelist[type-2]
      } else if (!isHomePage) {
        type = this.$route.query.type || 2
      }
      if (!keyword_name) {
        this.$message.warning('请输入关键字进行搜索')
        return
      }
      this.$emit('search', {
        keyword_name,
        type,
        page: 1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$placeholder-color: #b2b2b2;
$nav-placeholder-color: $font-theme-color;
$transition: height 0.2s;

.search-container {
  @include center-middle-flex;
  @include size(637px, 50px);
  @include font-bold($font-size-sl);
  border-radius: 25px;
  background: rgba($base-color, 0.6);
  color: $font-initialize-color;
  cursor: pointer;

  .input {
    flex: 1;
    border: 2px solid #F84949;
    border-right: none;
    border-radius: 45px 0px 0px 45px;
    @include center-middle-flex($justify: flex-start);
    height: 100%;

    //flex-grow: 1;
    .dropdown {
      @include center-middle-flex;
      height: 170%;
      margin-left: 32px;
      margin-right: 20px;
      position: relative;

      &-content {
        margin-right: 8px;
        color: #555555;
      }

      &-list {
        @include size(104px, 0);
        @include custom-position($position: absolute, $left: -30px, $top: 68px);
        @include flexbox;
        @include flex-direction(column);
        @include font-normal($size: $font-size-sl,
          $color: rgba(51, 51, 51, 0.8));
        background: $base-color;
        box-shadow: 0px 0px 12px 0px rgba(33, 48, 83, 0.12);
        transition: $transition;
        overflow: hidden;

        .item {
          flex-grow: 1;
          @include center-middle-flex;
        }

        .item:hover {
          color: $theme-color;
        }
      }

      /deep/.el-icon-arrow-down {
        color: #555555;
      }
    }

    .dropdown:hover .dropdown-list {
      height: 114px;
      z-index: 99;
      transition: $transition;
    }

    .input-text {
      height: 100%;
      font-size: $font-size-base;
      flex: 1;
      //@include size(413px, auto);
      border-left: 1px solid rgba(0, 0, 0, 0.25);

      /deep/ .el-input__inner {
        height: 100%;
        border: none;
        background: none;
        padding-left: 36px;

        &::-webkit-input-placeholder {
          color: #777777;
          // opacity: .76;
        }
      }

      &.color-white {
        /deep/ .el-input__inner {
          color: #777777
        }
      }

      &.dark_color {
        /deep/ .el-input__inner {
          color: #606266;

          &::-webkit-input-placeholder {
            color: #ffffff;
            // opacity: .76;
          }
        }
      }

      /deep/ input::-webkit-input-placeholder {
        color: $placeholder-color;
        font-size: $font-size-base;
      }

      /deep/ input::-moz-input-placeholder {
        color: $placeholder-color;
      }

      /deep/ input::-ms-input-placeholder {
        color: $placeholder-color;
      }
    }
  }

  .identify {
    height: 100%;
    padding-right: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .iden_icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 6px;
      background: rgba(255, 47, 15, 0.5);
      border-radius: 50%;
    }

    .iden_text {
      display: inline-block;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .btn {
    @include size(140px, 100%);
    @include center-middle-flex;
    background: #F84949;
    border-radius: 0 32px 32px 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    border: 2px solid #F84949;
    position: relative;

    i {
      margin-right: 11px;
      font-weight: 800;
    }
  }
}

.search-container-nav {
  border: none;
  background: $base-color;
  @include font-normal(14px);

  .input {
    .input-text {
      text-indent: 12px;
      font-size: $font-size-base;
      @include size(100%, 100%);
      border-left: none;
       /deep/ .el-input__inner {
        padding-left: 4px;
      }

      /deep/ input::-webkit-input-placeholder {
        color: $nav-placeholder-color;
        font-size: $font-size-xs;
      }

      /deep/ input::-moz-input-placeholder {
        color: $nav-placeholder-color;
      }

      /deep/ input::-ms-input-placeholder {
        color: $nav-placeholder-color;
      }
    }
  }
}
</style>
