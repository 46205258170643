<template>
  <div class="limit-music-list">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="limit-music-list-item"
    >
      <div class="music-info">
        <div
          class="cover"
          @click="handlePlayerMusic({ ...item, type: 2, labels: item.label })"
        >
          <el-image
            :fit="'cover'"
            class="cover-fixed"
            :src="require('@/assets/svg/record.svg')"
          />
          <img class="cover-main" :src="item.logo" />
          <div class="cover-btn">
            <img />
          </div>
        </div>
        <div class="content">
          <div class="content-title">{{ item.name }}</div>
          <div class="content-intro">BPM：{{ item.bpm }}</div>
          <div class="content-operation">
            <p class="content-operation-tag">
              <Tag
                v-for="(tag, tagIndex) in item.label.length
                  ? item.label.slice(0, 2)
                  : []"
                :key="tagIndex"
                >{{ tag.name }}
              </Tag>
            </p>
            <!-- <p class="content-operation-btn">
              <img
                :src="require('@/assets/svg/home-recommend-music-download.svg')"
                @click="handleDownload(item, 'single')"
              />
              <span
                class="btn-car"
                @click="
                  handleAddGoodsToCart({
                    object_id: item.object_id,
                    type: 2,
                    name: item.name
                  })
                "
                >加入购物车</span
              >
            </p> -->
          </div>
        </div>
      </div>
      <div class="btns">
        <div @click="handleMusicCollect(item)">{{item.is_collect=== 0 ? '收藏音乐' : '取消收藏'}}</div>
        <div @click="handleDownLoadForFree(item)">立即下载</div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "@/components/Tag";
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from "@/utils/author";

export default {
  name: 'LimitMusicCard',
  components: {
    Tag
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  mixins: [Buttons],
  computed: {
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
  },
  methods: {
    show(){
      this.$parent.$refs.vipTipDialog.showModal()
    },
    async handleMusicCollect(item) {
      if (this.musicInfo && this.musicInfo.object_id) {
        if (item.object_id === this.musicInfo.object_id) {
          this.$message.warning('正在播放的音乐请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, item.type || 2, {
        ...item,
        type: 2,
        labels: item.label
      }, false)
      // 收藏页面取消收藏后需重新拉取列表数据
      if (this.target === 'collect') {
        this.$emit('againGetList', 2)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.limit-music-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  width: 100%;
  margin-top: 36px;

  &-item:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
    border: none;
  }

  &-item {
    @include center-middle-flex($align: flex-start);
    flex-direction: column;
    background: $base-color;
    @include size(100%, 160px);
    border: 2px solid rgba(77, 77, 79, 0.12);
    cursor: pointer;
    .music-info {
      @include size(100%, 120px);
      @include center-middle-flex($justify: flex-start);
      padding: 15px 16px;
    }
    .cover {
      @include size(123px, 92px);
      margin-right: 20px;
      position: relative;
      border-radius: 2px;

      &-fixed {
        @include square(82px);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      &-main {
        @include square(92px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 2px;
        object-fit: cover;
      }

      &-btn {
        @include square(92px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
      }
    }

    .content {
      width: calc(100% - 123px);
      &-title {
        @include font-bold($size: 14px);
        line-height: 20px;
        color: #333333;
      }

      &-intro {
        @include font-normal($size: 12px);
        color: rgba(77, 77, 79, 0.48);
        line-height: 16px;
        margin: 4px 0 10px 0;
      }

      &-operation {
        @include center-middle-flex($justify: space-between);

        .vf-tag-container {
          color: rgba(77, 77, 79, 0.8);
          border-radius: 4px;
          padding: 2px 8px;
        }
        .vf-tag-block {
          margin-right: 4px;
        }

        .vf-tag-block:nth-last-of-type(1) {
          margin-right: 0;
        }

        &-btn {
          @include center-middle-flex;

          img {
            @include square(24px);
            margin-right: 4px;
          }

          .btn-car {
            @include size(70px, 24px);
            @include font-normal($size: 12px, $color: $font-initialize-color);
            line-height: 24px;
            text-align: center;
            background: linear-gradient(225deg, #e52a0d 0%, #ff7d68 100%);
            border-radius: 2px;
          }
        }
      }
    }
    .btns {
      @include size(100%, 40px);
      @include center-middle-flex($justify: flex-start);
      font-size: 14px;
      border-top: 1px solid rgba(77, 77, 79, 0.12);
      div{
        @include center-middle-flex($align: center);
        width: 50%;
        height: 100%;
      }
      div:nth-child(1){
        border-right: 1px solid rgba(77, 77, 79, 0.12);
      }
      div:nth-child(2) {
        color: #E52A0D;
        font-weight: 600;
      }
    }
  }
}
</style>
