<template>
  <div class="limit-exempt-container">
    <!-- banner -->
    <div class="banner">
      <div class="banner-container">
        <img src="@/assets/image/limitExempt/limit-exempt-bg_0901.jpg" alt="">
      </div>
      <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="banner-tips container_media">
        <span>温馨提示：此专区仅限于个人学习和参考，不可商用，素材需注明来源于PlayList</span>
        <span>* 此专区素材所有解释权归北京数音未来科技有限公司</span>
      </div>

      <div class="search">
        <div class="centerShow">
          <h3>限免专区 精选</h3>
          <h4>新注册用户，首月即可享用1次免费下载权限</h4>
          <!-- 搜索框 -->
          <Search ref="searchRef" class="search-input" style="margin: 0 auto" :limitExempt="true"
            placeholder="请输入关键词搜索音乐、视频、音效" @search="handleSearch" />
        </div>
      </div>

    </div>
    <div class="content">
      <div class="metaril-container">
        <div class="metaril container_media">
          <div class="card_items">
            <img src="@/assets/image/limitExempt/jxsc_icon.svg" alt="">
            <div>
              <span>精选素材</span>
              <p>音乐 / 视频 / 音效</p>
            </div>
          </div>
          <div class="card_items">
            <img src="@/assets/image/limitExempt/mfhq_icon.svg" alt="">
            <div>
              <span>免费获取</span>
              <p>限时免费下载专区 正版无水印素材</p>
            </div>
          </div>
          <div class="card_items">
            <img src="@/assets/image/limitExempt/zxkf_icon.svg" alt="">
            <div>
              <span>专享客服</span>
              <p>7*24小时全天在线专业解答</p>
            </div>
          </div>
        </div>
        <div class="title">
          <div><span>精选</span><i>素材</i></div>
          <p>SELECTED MATERIAL</p>
        </div>
        <div class="parts container_media">
          <div class="part-item" @mouseenter="handleMouseover(1)" @mouseleave="handleMouseleave" @click="goDetail('music')">
            <div class="hover-part" v-if="partHoverIndex == 1">
                <img class="icon" src="@/assets/image/limitExempt/music_hover_icon.svg" alt="">
                <span >查看详情<img src="@/assets/image/limitExempt/part_right_icon.svg" alt=""></span>
            </div>
            <span v-else>音乐专区</span>
          </div>
          <div class="part-item" @mouseenter="handleMouseover(2)" @mouseleave="handleMouseleave" @click="goDetail('video')">
            <div class="hover-part" v-if="partHoverIndex == 2">
              <img class="icon"  src="@/assets/image/limitExempt/video_hover_icon.svg" alt="">
              <span>查看详情<img src="@/assets/image/limitExempt/part_right_icon.svg" alt=""></span>
            </div>
            <span v-else>视频专区</span>
          </div>
          <div class="part-item" @mouseenter="handleMouseover(3)" @mouseleave="handleMouseleave" @click="goDetail('sound')">
            <div class="hover-part" v-if="partHoverIndex == 3">
              <img class="icon"  src="@/assets/image/limitExempt/sound_hover_icon.svg" alt="">
              <span>查看详情<img src="@/assets/image/limitExempt/part_right_icon.svg" alt=""></span>
            </div>
            <span v-else>音效专区</span>
          </div>
        </div>
      </div>
      <div class="music-container">
        <div class="title">
          <span>限免音乐·精选</span>
        </div>
        <div class="music-cards container_media">
          <music-card :list.sync="musicList"></music-card>
        </div>
        <div class="lookMore" @click="goDetail('music')">查看更多</div>
      </div>
      <div class="video-container">
        <div class="title">
          <span>限免视频·精选</span>
        </div>
        <div class="video-cards container_media">
          <template v-if="videoList.length > 0">
            <div class="video-card" v-for="(data, idx) in videoList" :key="idx">
              <div class="video-items">
                <VideoCard :isFree="true" :video="data || {}" @download="handleDownLoadForFree(data)"/>
                <p class="name">{{ data.name }}</p>
              </div>
            </div>
          </template>
        </div>
        <div class="lookMore" @click="goDetail('video')">查看更多</div>
      </div>

      <div class="sound--container">
        <div class="title">
          <span>限免音效·精选</span>
        </div>
        <div class="sound-wrap container_media">
          <sound-card :list="soundList"></sound-card>
        </div>
        <div class="lookMore" @click="goDetail('sound')">查看更多</div>
      </div>

      <div class="buy-container">
        <div class="desc">
          <p>限免专区音乐、视频、音效素材免费下载</p>
          <p>开通会员 可享权益</p>
        </div>
        <div class="buy-content container_media">
          <div class="buy-items">
            <div class="buy-top">
              <img src="@/assets/image/limitExempt/sqfw_icon.svg" alt="">
            </div>
            <div class="buy-desc">
              <span>授权范围</span>
              <p>自媒体平台（中国）、个人直播、电台、个体店铺背景音乐、产品内置、个人影视作品、家庭视频等</p>
            </div>
          </div>
          <div class="buy-items">
            <div class="buy-top">
              <img src="@/assets/image/limitExempt/xz_icon.svg" alt="">
            </div>
            <div class="buy-desc">
              <span>下载内容</span>
              <p>精选优质正版素材，包含音乐、视频、音效、图片、AI语音</p>
            </div>
          </div>
          <div class="buy-items">
            <div class="buy-top">
              <img src="@/assets/image/limitExempt/hyqy_icon.svg" alt="">
            </div>
            <div class="buy-desc">
              <span>会员权益</span>
              <p>人工定制选曲，不限下载次数</p>
            </div>
          </div>
        </div>
        <div class="buy" @click="goPackage">订阅会员 全站资源任意选</div>
      </div>
      <div class="tips container_media">
        <div class="content">
          <img src="../../assets/image/limitExempt/tips_icon.png" alt="">
          <div class="tip-title">免费专区用户使用须知：</div>
          <div class="content-text">
            <p>1、新注册用户首月可享1次使用权限（下载后多次授权视为使用多次），具体使用权限扣除方法请参考帮助中心；</p>
            <p>2、请您于下载后15个工作日内激活授权书，超时未能使用或激活的次数将自动失效；</p>
            <p>3、限免专区内素材授权范围请参考PlayList个人会员授权范围，超出个人会员授权范围请另行购买企业会员，限免专区内素材授权范围不可升级至企业会员；</p>
            <p>4、使用限免专区内素材制作作品投放时请署名“素材授权来自PlayList官网”。</p>
            <p>5、本专区活动最终解释权归北京数音未来科技有限公司所有。</p>
          </div>
        </div>
      </div>
    </div>
    <VipTipDialog ref="vipTipDialog"></VipTipDialog>
  </div>
</template>

<script>
import Search from '@/components/Search'
import Buttons from '@/layout/mixins/Buttons'
// import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
import MusicCard from './components/musicCard.vue'
import VideoCard from './components/videoCard.vue'
import SoundCard from './components/soundCard.vue'
import VipTipDialog from './components/VipTipDialog.vue'
import EventBus from '@/utils/bus.js'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'
import { fetchFreeTopList } from "@/api/activity";

export default {
  name: 'LimitExempt',
  components: { Search, MusicCard, VideoCard, SoundCard, VipTipDialog, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '限免专区'}
      ],
      partHoverIndex: 0,
      musicList: [],
      videoList: [],
      soundList: [],
      fetchLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    // keyword_name() {
    //   if (this.tagList.search.length > 0 && this.tagList.search.length == 1) {
    //     return this.tagList.search[0].label_name
    //   } else if (this.tagList.search.length >= 2) {
    //     let tagName = [];
    //     tagName = this.tagList.search.map((item) => {
    //       return item.label_name
    //     })
    //     return tagName
    //   } else {
    //     return ''
    //   }
    // }
  },
  watch: {
    '$store.state.music.tmpMusicInfo'(val) {
      const { is_collected, object_id, type } = val || {}
      if (val) {
        const list = type == 2 ? this.musicList : type == 3 ? this.soundList : []
        if (object_id) {
          const index = list.findIndex((i) => i.object_id === object_id)
          if (index > -1) {
            list[index].is_collect = is_collected
            this.$emit('update:data', list)
          }
        }
      }
    },
  },
  async created() {
    await this.getMetairl();

  },
  mounted(){
    EventBus.$on("collect", (data) => {
      const index = this.videoList.findIndex((i) => i.object_id === data.object_id)
      if(index > -1) this.videoList[index].is_collect = data.is_collect
    });
  },
  mixins: [Buttons],
  methods: {
    show(){
      this.$refs.vipTipDialog.showModal()
    },
    handleSearch(info){
      const {keyword_name, type} = info
      console.log('搜索-挑战列表页', info);
      this.$router.push({
        path: '/limitDetails',
        query: {
          type,
          keyword_name
        }
      })
    },
    handleMouseover(index) {
      this.partHoverIndex = index
    },
    handleMouseleave() {
      this.partHoverIndex = 0
    },
     /**
     * 获取素材列表
     */
    async getMetairl() {
      try {
        const { data } = await fetchFreeTopList();
        this.musicList = data.music;
        this.videoList = data.video
        this.soundList = data.sound
      } catch (e) {
        console.log(e);
      }
    },
    goDetail(type) {
      this.$store.dispatch('user/getUserInfo');
      this.$router.push({
        path: '/limitDetails',
        query: {
          type,
        }
      })
    },
    goPackage(){
      this.$router.push('/limitPackage')
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.limit-exempt-container {
  min-height: 100vh;

  min-width: 1000px;
  padding-bottom: 60px;

  .banner {
    width: 100%;
    position: relative;
    .crumb-wapper {
      width: 100%;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
    .banner-container {
      width: 100%;
      height: calc(340px + 60px);
      padding-top: 60px;
      box-sizing: border-box;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .search {
      position: absolute;
      @include size(100%, auto);
      left: 0;
      top: 60px;
      transform: translateY(45%);

      h3, h4 {
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
      }
      h3 {
        font-size: 48px;
        font-weight: 600;
        margin: 0 0 12px 0;
      }
      h4 {
        font-size: 16px;
        letter-spacing: 6px;
        margin: 0 0 34px 0;
      }

      /deep/.search-container {
        @include size(786px, 56px);
        border-radius: 0px!important;
        .input {
          border: none;
          background: rgba(255, 255, 255, 0.8);
          opacity: .8;
          border-radius: 0px!important;
          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-moz-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .btn {
          font-size: 18px;
          border-radius: 0px!important;
        }
      }
    }
    .banner-tips {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: rgba(255, 255, 255, 0.80);
      font-size: 12px;
      bottom: 8px;
    }

    .hot-tag-box {
      width: 637px;
      overflow: hidden; // 溢出隐藏
      display: -webkit-box; //作为弹性盒伸缩模型
      -webkit-box-orient: vertical; // 从上到下垂直排列
      -webkit-line-clamp: 1; //  显示的行数
      margin: 16px auto 0px;
      line-height: 20px;

      &-item {
        @include font-normal($size: 14px, $color: rgba(255, 255, 255, 0.8));
      }

      &-item:hover {
        color: $theme-color;
      }
    }
  }

  /deep/ {
    .search-container {
      .input-text {
        color: #ffffff;

        .el-input__inner {
          color: #ffffff;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding-left: 24px;
        }

        .el-input__inner::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .content {
    .title {
      font-size: 36px;
      @include center-middle-flex($justify: center);
      flex-direction: column;
      font-weight: bold;
      span{
        color: #333333;
        line-height: 50px;
      }
      i {
        font-style: normal;
        line-height: 50px;
        color: #E52A0D;
      }
      p {
        margin-top: 8px;
        letter-spacing: 2px;
        line-height: 28px;
        font-size: 20px;
        color: #C3C3CD;
      }
    }
    .lookMore {
      cursor: pointer;
      width: 160px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #333333;
      text-align: center;
      line-height: 44px;
      font-size: 20px;
      color: #333333;
      margin: 0 auto;
    }
    .metaril-container {
      padding-bottom: 50px;
    }
    .metaril {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px 24px;
      margin-top: 30px;
      margin-bottom: 50px;
      .card_items {
        @include size(100%, 120px);
        background:rgba(229, 42, 13, 0.05);
        padding: 0 32px;
        box-sizing: border-box;
        @include center-middle-flex($justify: flex-start);
        img {
          @include size(84px, 72px);
          margin-right: 18px;
        }
        span {
          color: #333333;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p{
          color: #999999;
          font-size: 14px;
        }
      }
    }
    .parts {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px 24px;
      margin-top: 36px;
      .part-item {
        @include size(100%, 215px);
        cursor: pointer;
        @include center-middle-flex($justify: center);
        span {
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 2px;
        }
        .hover-part {
          @include center-middle-flex($align: center);
          flex-direction: column;
          span{
            margin-top: 24px;
            position: relative;
            img{
              position: absolute;
              bottom: 6px;
              right: -32px;
            }
          }
        }
      }
      .part-item:nth-child(1){
        background: url("../../assets/image/limitExempt/yyzq_bg.jpg") no-repeat;
        background-size: 100% 100%;
      }
      .part-item:nth-child(2){
        background: url("../../assets/image/limitExempt/spzq-bg.jpg") no-repeat;
        background-size: 100% 100%;
      }
      .part-item:nth-child(3){
        background: url("../../assets/image/limitExempt/yxzq-bg.jpg") no-repeat;
        background-size: 100% 100%;
      }

    }
    .music-container {
      background-color: #FAFAFA;
      padding: 60px 0 40px 0;
      .music-cards {
        margin-bottom: 36px;
      }
    }
    .video-container {
      padding: 70px 0 50px 0;
      .video-cards {
        margin-top: 36px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0px 24px;
        width: 100%;
        .video-items {
          @include size(100%, 64%);
          border-radius: 4px;
          margin-bottom: 20px;
          .name {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            font-weight: 600;
            margin-top: 12px
          }
          video {
            @include size(100%, 100%);
            object-fit: fill;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
          }

          .coverimg {
            @include size(100%, 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
          }

          .loading {
            display: inline-block;
            font-size: 30px;
            position: absolute;
            top: 45%;
            left: 45%;
            color: #fff;
          }
        }
      }
    }
    .sound--container {
      background-color: #FAFAFA;
      padding: 60px 0 40px 0;
      .sound-wrap {
        margin-top: 36px;
        margin-bottom: 36px;
      }
    }
    .buy-container {
      padding: 70px 0 50px 0;
      .desc {
        @include center-middle-flex($justify: center);
        flex-direction: column;
        font-size: 24px;
        color: #333333;
        font-weight: 600;
        line-height: 36px;
      }
      .buy-content {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px 24px;
        margin-top: 50px;
        margin-bottom: 50px;
        .buy-items {
          .buy-top {
            background: url("../../assets/image/limitExempt/buy-bg.png") no-repeat;
            background-size: 100% 100%;
            @include size(100%, 110px);
            @include center-middle-flex($justify: center);
            img{
              @include size(56px, 56px);
            }
          }
          .buy-desc {
            @include size(100%, 168px);
            border-radius: 0px  0px  12px  12px;
            box-shadow: 0 5px 7px rgba(0, 0, 0, 0.10);
            @include center-middle-flex($justify: flex-start);
            flex-direction: column;
            padding: 30px 36px 0;
            span {
              font-size: 20px;
              color: rgba(0, 0, 0, 0.85);
              font-weight: 600;
              margin-bottom: 20px;
            }
            p {
              font-size: 16px;
              color: #999999;
            }
          }
        }
      }
      .buy {
        cursor: pointer;
        width: 360px;
        height: 52px;
        border-radius: 26px;
        text-align: center;
        line-height: 52px;
        font-size: 20px;
        color: #FFFFFF;
        margin: 0 auto;
        background-image: linear-gradient(to right, #FE7273, #FC4243);
      }
    }
    .tips {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @include size(100%, 272px);
      border-radius: 24px;
      border: 1px solid rgba(229, 42, 13, .5);
      margin-bottom: 70px;
      .content {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: #FDF4F3;
        border-radius: 16px;
        margin: auto;
        position: relative;
        img{
          width: 56px;
          height: 60px;
          position: absolute;
          top: 18px;
          right: 48px;
          z-index: 1;
        }
        .tip-title {
          height: 68px;
          color: #333333;
          font-size: 20px;
          font-weight: 600;
          margin-left: 38px;
          line-height: 68px;
        }
        .content-text {
          width: calc(100% - 40px);
          height: 172px;
          background-color: #fff;
          margin: 0 auto;
          border-radius: 12px;
          padding: 16px 28px;
          z-index: 2;
          position: relative;
          p{
            line-height: 28px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }

}
</style>
