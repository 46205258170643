<template>
  <el-dialog
    :visible='visible'
    top="15%"
    width='40%'
    title='开通会员提示'
    :before-close="handleCancel">
    <div class="content">您的免费下载权益剩余次数为 0 ，如需继续使用，请开通会员海量正版素材任您挑选。</div>
    <div slot="footer">
      <div class="buy" @click="goPackage">订阅会员 全站资源任意选</div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data(){
    return{
      visible: false
    }
  },
  methods: {
    // 关闭弹框
    handleCancel() {
      this.visible = false;
    },
    showModal() {
      console.log(11);
      this.visible = true
    },
    goPackage(){
      this.handleCancel()
      this.$router.push('/limitPackage')
    },
  }
}
</script>
<style scoped lang='scss'>
.content{
  padding: 0px 46px;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
}
.buy {
  cursor: pointer;
  width: 220px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #FFFFFF;
  margin: 0 auto;
  margin-bottom: 16px;
  background-image: linear-gradient(to right, #FE7273, #FC4243);
}
/deep/{
  .el-dialog__title {
    font-weight: bold;
  }
}
</style>
