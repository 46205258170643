import request from '@/utils/request'



export function ActivityCampingData(params) {
    return request.post('/fontend/music/luying', params, {})
}

// 限免专区
export function fetchFreeTopList(params) {
  return request.post('/fontend/music/free-list', params, {})
}
